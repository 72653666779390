import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconEdit } from '../../../images/IconEdit'
import { IStore } from '../../../types/storeTypes'
import { EditClientInfoModal } from '../../modals/editClientInfoModal'
import { useSelector } from 'react-redux'

interface Props {
  client: IStore['client']
  setClientInfo: (value: IStore['client']) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
  disableEdit?: boolean
  isSignature?: boolean
  isGlobalSave?: boolean
  buyer?: number
  isSpain?: boolean
}

export const ClientShortInfoBlock: React.FC<Props> = ({
  client,
  setClientInfo,
  formErrors,
  setFormErrors,
  requiredFields,
  disableEdit = false,
  isSignature = false,
  isGlobalSave = false,
  buyer,
  isSpain
}) => {
  const { t } = useTranslation()
  const [showEditClientModal, setShowEditClientModal] = useState(false)
  const dicts = useSelector((store: IStore) => store.dicts)

  const clientErrors = useMemo(() => {
    return formErrors.filter((formError) => requiredFields.includes(formError))
  }, [requiredFields, formErrors])

  const itemStyle = `w-full`
  const colStyle = `w-full flex flex-col gap-4 ${isSignature ? 'desktop:w-1/2' : 'xs:w-1/2'}`

  const allStates = dicts && dicts.states ? { ...dicts.states.CA, ...dicts.states.US } : null

  const state =
    client.province && allStates ? Object.keys(allStates).find((key) => allStates[key] === client.province) : ''

  return (
    <>
      <div className={`form_block ${isSignature ? 'w-full md:w-auto desktop:!min-w-[300px]' : ''}`}>
        <div className=' flex gap-0 desktop:gap-4 justify-between relative'>
          <div className='form_block_title'>{t('Client Details')}</div>
          {!disableEdit && (
            <button
              type='button'
              className='px-2 py-2 group disabled:pointer-events-none'
              onClick={() => setShowEditClientModal(true)}
            >
              <IconEdit
                classname={
                  clientErrors.length
                    ? 'text-red-500 group-hover:text-red-700'
                    : 'text-zinc-500 group-hover:text-zinc-700'
                }
              />
            </button>
          )}
        </div>

        <div
          className={`flex justify-between ${isSignature ? 'flex-row md:flex-col desktop:flex-row gap-4 desktop:gap-0' : 'flex-col xs:flex-row gap-4 xs:gap-0'}`}
        >
          <div className={colStyle}>
            {client.full_name && <div className={itemStyle}>{client.full_name}</div>}
            {client.email && (
              <div className={`${itemStyle} overflow-hidden whitespace-nowrap text-ellipsis pr-2`}>{client.email}</div>
            )}
            {client.phone_no && <div className={itemStyle}>{client.phone_no}</div>}
          </div>

          <div className={colStyle}>
            {client.address1 && <div className={itemStyle}>{client.address1}</div>}
            {client.address2 && <div className={itemStyle}>{client.address2}</div>}
            <div className={itemStyle}>
              {client.municipality ? `${client.municipality}${state || client.postal_code ? ', ' : ''}` : ''}
              {state} {client.postal_code}
            </div>
          </div>
        </div>
        {clientErrors.length > 0 && <div className='text-red-500'>{t('Please enter all required information')}*</div>}
      </div>

      <EditClientInfoModal
        showed={showEditClientModal}
        closeModal={() => setShowEditClientModal(false)}
        client={client}
        setClientInfo={setClientInfo}
        formErrors={clientErrors}
        setFormErrors={setFormErrors}
        requiredFields={requiredFields}
        isGlobalSave={isGlobalSave}
        buyer={buyer}
        isSpain={isSpain}
      />
    </>
  )
}
