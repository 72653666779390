import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IImage, IStore } from '../../../../types/storeTypes'
import { ClientInfoBlock } from '../../../formBlocks/clientInfoBlock'
import { IdentificationBlock } from '../../../formBlocks/identificationBlock'
import { BuyerAndSourceBlock } from '../../../formBlocks/buyerAndSourceBlock'
import { Loader } from '../../../loader'
import { callApi } from '../../../../utils/callApi'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { MessageModal } from '../../../modals/messageModal'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { useFormSession } from '../../../../hooks/useFormSession'
import { DefaultButton } from '../../../defaultButton'
import { updateClientInfo } from '../../../../store/clientInfoSlice'
import { setAppointmentBuyer } from '../../../../store/appointmentInfoSlice'
import { useTranslation } from 'react-i18next'
import { defaultClient } from '../../../../utils/defaultClient'
import { useGetConsignedStepData } from '../../../../hooks/useGetConsignedStepData'

const requiredFields = ['first_name', 'last_name', 'email', 'phone_no', 'country', 'email', 'buyer', 'ns_lead_source']

interface Props {}

export const FirsStep: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { stepsLoading, getSecondStepData, changeCurrentStep } = useGetConsignedStepData()
  const client = useSelector((store: IStore) => store.client)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const [clientInfo, setStateClientInfo] = useState<IStore['client']>(defaultClient)
  const [buyer, setBuyer] = useState<number>(0)
  const [uploadId, setUploadId] = useState<IImage[] | null>(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const dispatch = useDispatch()
  const { saveSession } = useFormSession()

  const isSpain = useMemo(() => {
    return appointmentInfo.appointment &&
      appointmentInfo.appointment.office.country &&
      appointmentInfo.appointment.office.country.toLowerCase() === 'es'
      ? true
      : false
  }, [appointmentInfo.appointment])

  const getOfferCurrency = () => {
    if (
      appointmentInfo.appointment &&
      appointmentInfo.appointment.office &&
      appointmentInfo.appointment.office.country
    ) {
      const country = appointmentInfo.appointment.office.country.toLowerCase()
      if (country === 'es') return 'EUR'
      else if (country === 'hk') return 'HKD'
      else return 'USD'
    }

    return 'USD'
  }

  useEffect(() => {
    const newClient = { ...client }
    if (client.identity && client.identity.height) {
      newClient.height_type = client.identity.height.measure
      newClient.height_1 =
        client.identity.height.measure === 'Metric'
          ? client.identity.height.value.cm || 0
          : client.identity.height.value.ft || 0
      newClient.height_2 = client.identity.height.value.in || 0
    }
    if (
      !client.country &&
      !!appointmentInfo &&
      !!appointmentInfo.appointment &&
      appointmentInfo.appointment?.office.country
    ) {
      newClient.country = appointmentInfo.appointment.office.country
    }
    if (client.photo_id_url) {
      setUploadId([
        {
          name: t('Upload ID Photo'),
          content: '',
          url: client.photo_id_url,
          mimetype: client.photo_id_mimetype || '',
        },
      ])
    }
    setStateClientInfo(newClient)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, appointmentInfo.appointment])

  useEffect(() => {
    if (appointmentInfo && appointmentInfo.buyer_id) {
      setBuyer(appointmentInfo.buyer_id)
    } else if (appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.buyer_id) {
      setBuyer(appointmentInfo.intermediate_save.buyer_id)
    }
  }, [appointmentInfo, appointmentInfo.buyer_id])

  const onSave = async () => {
    setSaveLoading(true)
    const errors = requiredFields
      .map((field) => {
        if (field === 'buyer') return !buyer ? field : ''
        else if (field === 'email') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'phone_no') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!clientInfo.postal_code && clientInfo.country !== 'HK') return field
        } else if (field === 'province') {
          if (!clientInfo.province && ['CA', 'US'].includes(clientInfo.country)) return field
        } else if (field === 'id_state') {
          if (
            !clientInfo.id_state &&
            ['US'].includes(clientInfo.country) &&
            [`Driver's License`].includes(clientInfo.id_type)
          ) {
            return field
          }
        } else if (field === 'GDPR') {
          if (!clientInfo.gdpr_url && isSpain) return field
        } else if (!clientInfo[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setOpenErrorModal(true)
      setFormErrors(errors)
      setSaveLoading(false)
      return null
    }

    try {
      const data = {
        first_name: clientInfo.first_name,
        last_name: clientInfo.last_name,
        email: clientInfo.email,
        phone: clientInfo.phone_no && clientInfo.phone_no.length > 5 ? clientInfo.phone_no : '',
        country: clientInfo.country,
        state: clientInfo.province,
        city: clientInfo.municipality,
        address1: clientInfo.address1,
        address2: clientInfo.address2,
        postal_code: clientInfo.postal_code,
        id_type: clientInfo.id_type,
        id_number: clientInfo.id_number,
        id_state: clientInfo.id_state,
        birthday: clientInfo.birthday,
        gender: clientInfo.gender,
        height: clientInfo.height_1
          ? {
              measure: clientInfo.height_type,
              value:
                clientInfo.height_type === 'Metric'
                  ? { cm: clientInfo.height_1 }
                  : {
                      ft: clientInfo.height_1,
                      in: clientInfo.height_2,
                    },
            }
          : null,
        id_file:
          uploadId && uploadId[0] && uploadId[0].url !== client.photo_id_url
            ? {
                url: uploadId[0].url,
                name: uploadId[0].name,
              }
            : null,
        buyer_id: buyer,
        source_id: clientInfo.ns_lead_source,
        nationality: clientInfo.nationality,
        profession: clientInfo.profession,
      }
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.firsStepSave}`,
        data,
        isConsignedConvert: true,
      })
      dispatch(
        updateClientInfo({
          ...clientInfo,
          photo_id_url: uploadId && uploadId[0] ? uploadId[0].url : null,
          photo_id_mimetype: uploadId && uploadId[0] ? uploadId[0].mimetype : null,
          full_name: `${data.first_name} ${data.last_name}`,
        })
      )
      dispatch(setAppointmentBuyer(buyer))
      const { intermediate_save } = appointmentInfo
      const consignmentConversionData =
        !!intermediate_save && !!intermediate_save?.consignmentConversion
          ? intermediate_save?.consignmentConversion
          : {}
      const saveData = {
        ...intermediate_save,
        buyer_id: buyer,
        client_name: `${data.first_name} ${data.last_name}`,
        office: appointmentInfo.appointment?.office,
        currency: getOfferCurrency(),
        client_id: clientInfo.id,
        appointment_id: appointmentInfo.appointment?.id,
        consignmentConversion: {
          ...consignmentConversionData,
          currentStep: 2,
        },
      }
      await saveSession(saveData)
      await getSecondStepData()
      changeCurrentStep(2)
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveLoading(false)
    }
  }

  return stepsLoading.includes(1) ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='grid grid-cols-1 tabletMd:grid-cols-2 desktop:grid-cols-3 gap-6 form_step_bar pb-4'>
        <ClientInfoBlock
          client={clientInfo}
          setClientInfo={setStateClientInfo}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          requiredFields={requiredFields}
        />
        <div className='flex flex-col w-full gap-6 [&>div]:w-full'>
          <IdentificationBlock
            client={clientInfo}
            setClientInfo={setStateClientInfo}
            uploadId={uploadId}
            setUploadId={setUploadId}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            requiredFields={requiredFields}
          />
          <div className='desktop:hidden w-full'>
            <BuyerAndSourceBlock
              buyer={buyer}
              setBuyer={setBuyer}
              client={clientInfo}
              setClientInfo={setStateClientInfo}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={requiredFields}
            />
          </div>
        </div>
        <div className='hidden desktop:flex w-full [&>div]:w-full'>
          <BuyerAndSourceBlock
            buyer={buyer}
            setBuyer={setBuyer}
            client={clientInfo}
            setClientInfo={setStateClientInfo}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            requiredFields={requiredFields}
          />
        </div>
      </div>
      <div className='flex justify-end my-5'>
        <DefaultButton
          title={`${t('Save and Continue')} →`}
          onClick={onSave}
          classname='main_button'
          loading={saveLoading}
          disabled={saveLoading}
        />
      </div>

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
      />
    </>
  )
}
