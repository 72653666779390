import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IImage, IStore } from '../../../../types/storeTypes'
import { useGetStepData } from '../../../../hooks/useGetStepData'
import { Loader } from '../../../loader'
import { IconPrint } from '../../../../images/IconPrint'
import { DefaultButton } from '../../../defaultButton'
import { EmailModal } from '../../../modals/emailModal'
import { IEmailSendData } from '../../../../hooks/useOfferEmails'
import { IconClose } from '../../../../images/IconClose'
import { resizeImage } from '../../../../utils/resizeImage'
import { trimString } from '../../../../utils/trimString'
import { PhotoUploader } from '../../photoUploader'
import { callApi } from '../../../../utils/callApi'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { useOfferDocs } from '../../../../hooks/useOfferDocs'

export const SixthStep = () => {
  const { t } = useTranslation()
  const confirmationData = useSelector((store: IStore) => store.confirmationData)
  const { stepsLoading } = useGetStepData()
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [uploadId, setUploadId] = useState<IImage[] | null>(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteImageLoading, setDeleteImageLoading] = useState(false)
  const dispatch = useDispatch()
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const notes = appointmentInfo.intermediate_save?.decisionNotes || ''
  const { consignmentContractLoading, loadConsignmentContract } = useOfferDocs()
  const isSpain =
    appointmentInfo.intermediate_save &&
    appointmentInfo.intermediate_save.office &&
    appointmentInfo.intermediate_save.office.country &&
    appointmentInfo.intermediate_save.office.country.toLowerCase() === 'es'
      ? true
      : false

  const emailFiles = useMemo(() => {
    const arr: IEmailSendData['attachments'] = []
    if (!!confirmationData.consignment_agreement) arr.push('consignment')
    if (!!confirmationData.bulk_photo) {
      setUploadId([{ url: confirmationData.bulk_photo, name: 'All items photo', content: '', mimetype: '' }])
    }

    return arr
  }, [confirmationData])

  const uploadAllItemsPhoto = async (image: IImage[]) => {
    const data = {
      file: {
        url: image[0] ? image[0].url : '',
        name: image[0] ? image[0].name : '',
      },
    }
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.sixthStep}`,
        data,
      })
      if (resp && resp.data && resp.data.success) setUploadId(image)
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setSaveLoading(false)
    }
  }

  const deleteAllItemsPhoto = async () => {
    setDeleteImageLoading(true)
    try {
      const resp = await callApi({
        method: 'DELETE',
        url: `${ApiRoutes.agreements}?agreement=bulk_photo`,
      })
      if (resp && resp.data && resp.data.success) {
        setUploadId(null)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setDeleteImageLoading(false)
    }
  }

  return stepsLoading.includes(6) || !confirmationData.offer_id ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col'>
        <div className='w-full bg-white rounded-lg min-h-[70vh] max-h-auto mb-4 py-6 overflow-y-auto custom_scroll flex justify-center items-center'>
          <div className='flex flex-col justify-center items-center'>
            <div className=' font-serif text-4xl leading-[43px] text-center'>
              {confirmationData.client_name} {t('Appointment Complete')}!{' '}
              {confirmationData.link ? (
                <span className='whitespace-nowrap'>
                  {t('Offer')}{' '}
                  <a
                    href={confirmationData.link}
                    target='_blank'
                    className='underline underline-offset-4 decoration-2 text-blue-500'
                    rel='noreferrer'
                  >
                    #B{confirmationData.offer_id}
                  </a>
                </span>
              ) : (
                <span className='whitespace-nowrap'>
                  {t('Offer')} #B{confirmationData.offer_id}
                </span>
              )}
            </div>

            <div className='text-lg text-zinc-700 mt-4'>{t('You can close this window')}</div>

            {!!confirmationData.consignment_agreement ? (
              <div className='flex justify-between items-center w-[360px] gap-4 mt-6'>
                <div className={`flex form_label `}>{t('Picture of all items')}</div>

                {uploadId && uploadId[0] ? (
                  <div className='flex items-center gap-2 w-[160px] relative'>
                    {deleteImageLoading && (
                      <div className='absolute inset-0 bg-zinc-300 bg-opacity-20 flex justify-center items-center'>
                        <Loader />
                      </div>
                    )}
                    <div className='h-[50px] w-[50px] border border-zinc-200 rounded flex justify-center items-center'>
                      <img src={resizeImage(uploadId[0].url)} alt='upload-id' className='max-w-full max-h-full' />
                    </div>
                    <div className='text-xs text-zinc-500'>{trimString(uploadId[0].name, 8)}</div>
                    <button type='button' onClick={deleteAllItemsPhoto}>
                      <IconClose />
                    </button>
                  </div>
                ) : saveLoading ? (
                  <Loader />
                ) : (
                  <PhotoUploader
                    blockStyle='flex flex-col gap-2'
                    desktopButtonText='Choose file'
                    desktopButtonStyle='identification-image-upload-button w-[160px] !p-1'
                    showQRCodeButton
                    qrcodeButtonText='From mobile'
                    qrcodeButtonStyle='identification-image-upload-button w-[160px] !p-1'
                    callback={(image) => uploadAllItemsPhoto(image)}
                  />
                )}
              </div>
            ) : null}

            <div className='flex flex-col gap-7 mt-6'>
              {confirmationData.consignment_agreement && (
                <div className='flex justify-between items-center w-[360px] gap-4'>
                  <div className=''>{t('Consignment Form')}</div>
                  <a
                    href={confirmationData.consignment_agreement}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='grey_button !w-[160px] !min-w-[160px]'
                  >
                    <IconPrint />
                    {t('Print')}
                  </a>
                </div>
              )}
              {isSpain && (
                <>
                  {confirmationData.consignment_agreement && (
                    <div className='flex justify-between items-center w-[360px] gap-4'>
                      <div className=''>{t('Consignment Agreement')}.docx</div>
                      <DefaultButton
                        title={t('Download file')}
                        icon='download'
                        classname='grey_button !w-[160px] !min-w-[160px]'
                        onClick={() => loadConsignmentContract()}
                        disabled={consignmentContractLoading}
                        loading={consignmentContractLoading}
                        iconStyle='text-zinc-500'
                      />
                    </div>
                  )}
                  {confirmationData.gdpr_url && (
                    <div className='flex justify-between items-center w-[360px] gap-4'>
                      <div className=''>{t('GDPR file')}.pdf</div>
                      <a
                        href={confirmationData.gdpr_url}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='grey_button !w-[160px] !min-w-[160px]'
                      >
                        <IconPrint />
                        {t('Print')}
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className='mt-7 flex justify-end w-[360px]'>
              <DefaultButton
                title={t('Email')}
                onClick={() => setShowSendEmail(true)}
                classname='grey_button !w-[160px] !min-w-[160px]'
                disabled={showSendEmail}
                icon='email'
              />
            </div>
          </div>
        </div>
      </div>

      <EmailModal
        showed={showSendEmail}
        closeModal={() => setShowSendEmail(false)}
        files={emailFiles}
        decisionNotes={notes}
      />
    </>
  )
}
