import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../modal'
import { IconClose } from '../../../images/IconClose'
import { IStore } from '../../../types/storeTypes'
import { ClientInfoBlock } from '../../formBlocks/clientInfoBlock'
import { DefaultButton } from '../../defaultButton'
import { callApi } from '../../../utils/callApi'
import { ApiRoutes } from '../../../utils/apiRoutes'
import { useDispatch } from 'react-redux'
import { updateClientInfo } from '../../../store/clientInfoSlice'
import { showGlobalPopup } from '../../../store/globalPopupSlice'

interface Props {
  showed: boolean
  closeModal: () => void
  client: IStore['client']
  setClientInfo: (value: IStore['client']) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
  isGlobalSave?: boolean
  buyer?: number
  isSpain?: boolean
}

export const EditClientInfoModal: React.FC<Props> = ({
  showed,
  closeModal,
  client,
  setClientInfo,
  formErrors,
  setFormErrors,
  requiredFields,
  isGlobalSave = false,
  buyer,
  isSpain
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalClientInfo, setModalClientInfo] = useState(client)
  const [saveLoading, setSaveLoading] = useState(false)

  const onClose = () => {
    const errors = requiredFields
      .map((field) => {
        if (field === 'buyer') return ''
        else if (field === 'email') {
          if (!client.email && (!client.phone_no || client.phone_no.length < 5)) return field
        } else if (field === 'phone_no') {
          if (!client.email && (!client.phone_no || client.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!client.postal_code && client.country !== 'HK') return field
        } else if (field === 'province') {
          if (!client.province && ['CA', 'US'].includes(client.country)) return field
        } else if (field === 'id_state') {
          if (!client.id_state && ['US'].includes(client.country) && [`Driver's License`].includes(client.id_type)) {
            return field
          }
        } else if (!client[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)
    setFormErrors(errors)
    setModalClientInfo(client)
    closeModal()
  }

  useEffect(() => {
    setModalClientInfo(client)
  }, [client])

  const onSave = async () => {
    const errors = requiredFields
      .map((field) => {
        if (field === 'buyer') return ''
        else if (field === 'email') {
          if (!modalClientInfo.email && (!modalClientInfo.phone_no || modalClientInfo.phone_no.length < 5)) return field
        } else if (field === 'language') {
          if (isSpain && !modalClientInfo.language) return field
        }else if (field === 'phone_no') {
          if (!modalClientInfo.email && (!modalClientInfo.phone_no || modalClientInfo.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!modalClientInfo.postal_code && modalClientInfo.country !== 'HK') return field
        } else if (field === 'province') {
          if (!modalClientInfo.province && ['CA', 'US'].includes(modalClientInfo.country)) return field
        } else if (field === 'id_state') {
          if (
            !modalClientInfo.id_state &&
            ['US'].includes(modalClientInfo.country) &&
            [`Driver's License`].includes(modalClientInfo.id_type)
          ) {
            return field
          }
        } else if (!modalClientInfo[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setFormErrors(errors)
      return null
    }

    if (isGlobalSave) {
      let success = true
      try {
        setSaveLoading(true)
        const data = {
          first_name: modalClientInfo.first_name,
          last_name: modalClientInfo.last_name,
          email: modalClientInfo.email,
          phone: modalClientInfo.phone_no && modalClientInfo.phone_no.length > 5 ? modalClientInfo.phone_no : '',
          country: modalClientInfo.country,
          state: modalClientInfo.province,
          city: modalClientInfo.municipality,
          address1: modalClientInfo.address1,
          address2: modalClientInfo.address2,
          postal_code: modalClientInfo.postal_code,
          id_type: modalClientInfo.id_type,
          id_number: modalClientInfo.id_number,
          id_state: modalClientInfo.id_state,
          birthday: modalClientInfo.birthday,
          gender: modalClientInfo.gender,
          height: modalClientInfo.height_1
            ? {
                measure: modalClientInfo.height_type,
                value:
                  modalClientInfo.height_type === 'Metric'
                    ? { cm: modalClientInfo.height_1 }
                    : {
                        ft: modalClientInfo.height_1,
                        in: modalClientInfo.height_2,
                      },
              }
            : null,
          id_file: null,
          buyer_id: buyer,
          source_id: modalClientInfo.ns_lead_source,
          nationality: modalClientInfo.nationality,
          profession: modalClientInfo.profession,
          language: modalClientInfo.language
        }
        await callApi({
          method: 'POST',
          url: `${ApiRoutes.firsStepSave}`,
          data,
        })
        dispatch(
          updateClientInfo({
            ...modalClientInfo,
            full_name: `${data.first_name} ${data.last_name}`,
          })
        )
      } catch (error) {
        console.log(error, 'error data')
        success = false
        dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      } finally {
        if (success) closeModal()
        setSaveLoading(false)
      }
    } else {
      setClientInfo({
        ...modalClientInfo,
        full_name: `${modalClientInfo.first_name} ${modalClientInfo.last_name}`,
        phone_no: modalClientInfo.phone_no && modalClientInfo.phone_no.length > 5 ? modalClientInfo.phone_no : '',
      })
      closeModal()
    }
  }

  return (
    <Modal showed={showed} bodyStyle='!w-[600px] !max-w-[95vw] xl:!max-w-[80vw] relative'>
      <div className='absolute top-3 right-5'>
        <button type='button' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <div className='font-medium text-[28px] leading-9 text-zinc-800 mb-2'>
        {t('Edit {{name}} client details', {
          name: client.full_name,
        })}
      </div>
      <div className='flex flex-col gap-6 max-h-[80vh] overflow-y-auto custom_scroll mx-[-16px]'>
        <ClientInfoBlock
          client={modalClientInfo}
          setClientInfo={setModalClientInfo}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          requiredFields={requiredFields}
          isModal
        />

        <div className='flex justify-end px-4'>
          <DefaultButton
            title={t('Save')}
            onClick={() => onSave()}
            disabled={formErrors.length > 0 || saveLoading}
            classname='main_button w-[200px]'
            loading={saveLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
