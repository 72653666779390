import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IImage, IStore } from '../../../types/storeTypes'
import { useSelector } from 'react-redux'
import { PhotoUploader } from '../../home-page/photoUploader'
import { IconClose } from '../../../images/IconClose'
import { resizeImage } from '../../../utils/resizeImage'
import { trimString } from '../../../utils/trimString'
import { MultiSelect } from '../../multiSelect'
import { IconPdf } from '../../../images/IconPdf'
import moment from 'moment'

interface Props {
  client: IStore['client']
  setClientInfo: (value: IStore['client']) => void
  uploadId: IImage[] | null
  setUploadId: (image: IImage[] | null) => void
  formErrors: string[]
  setFormErrors: (value: string[]) => void
  requiredFields: string[]
}
export const IdentificationBlock: React.FC<Props> = ({
  client,
  setClientInfo,
  uploadId,
  setUploadId,
  formErrors,
  setFormErrors,
  requiredFields,
}) => {
  const dicts = useSelector((store: IStore) => store.dicts)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const { t, i18n } = useTranslation()

  const idTypesList = useMemo(() => {
    if (!dicts || !dicts.id_types) return []
    const list = dicts.id_types
    return list.map((item) => {
      return { label: item, id: item, value: item }
    })
  }, [dicts])

  const defaultIdType = useMemo(() => {
    return client.id_type ? idTypesList.find((listItem) => listItem.value === client.id_type) : null
  }, [client.id_type, idTypesList])

  const gendersList = useMemo(() => {
    if (!dicts || !dicts.genders) return []
    return dicts.genders.map((gender) => {
      return { label: t(gender), id: gender, value: gender }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicts, i18n.language])

  const defaultGender = useMemo(() => {
    return client.gender ? gendersList.find((listItem) => listItem.value === client.gender) : null
  }, [client.gender, gendersList])

  const heightList = useMemo(() => {
    return [
      { label: 'Cm', id: 'Cm', value: 'Metric' },
      { label: 'Ft/In', id: 'Ft/In', value: 'Ft/In' },
    ]
  }, [])

  const defaultHeightType = useMemo(() => {
    return client.height_type ? heightList.find((listItem) => listItem.value === client.height_type) : heightList[1]
  }, [client.height_type, heightList])

  const statesList = useMemo(() => {
    if (!dicts || !dicts.states || !client.country) return []
    const index = 'US'
    const list = Object.entries(dicts.states[index])
    return list.map((state) => {
      return { label: state[1], id: state[0], value: state[1] }
    })
  }, [dicts, client.country])

  const defaultIdState = useMemo(() => {
    return client.id_state
      ? statesList.find(
          (state) =>
            state.value === client.id_state ||
            (client.id_state && state.label.toLowerCase() === client.id_state.toLowerCase())
        )
      : null
  }, [client.id_state, statesList])

  useEffect(() => {
    if (!client.height_type && defaultHeightType) setClientInfo({ ...client, height_type: defaultHeightType.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.height_type, defaultHeightType])

  const editField = (name: keyof IStore['client'], value: any) => {
    let filteredErrors = formErrors.filter((error) => error !== name)
    if (name === 'height_type') {
      setClientInfo({ ...client, height_type: value, height_1: 0, height_2: 0 })
    } else if (name === 'id_type') {
      setClientInfo({ ...client, id_type: value, id_state: '' })
      filteredErrors = filteredErrors.filter((error) => error !== 'id_state')
    } else {
      setClientInfo({ ...client, [name]: value })
    }
    setFormErrors(filteredErrors)
  }

  const maxDate = moment().subtract(18, 'years').format('YYYY-12-31')

  const editBirthday = (date: string) => {
    const maxYear = moment().year() - 18
    const isAfter = moment(date).isAfter(moment().subtract(18, 'years'))
    const newMaxDate = moment(date).year(maxYear).format('YYYY-MM-DD')

    editField('birthday', isAfter ? newMaxDate : date)
  }

  return (
    <div className='form_block h-fit'>
      <div className='form_block_title'>{t('Identification')}</div>
      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-id_type'
          className={`w-1/3 flex form_label ${formErrors.includes('id_type') ? 'error_label' : ''}`}
        >
          {t('ID Type')}
          {requiredFields.includes('id_type') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <MultiSelect
          selectId='select-client-id_type'
          inputId='input-client-id_type'
          options={idTypesList}
          onChange={(value) => editField('id_type', value)}
          value={defaultIdType}
          isClearable
          containerStyles='w-2/3'
          controlFocusedStyles='form-select form-select-focused'
          controlDefaultStyles={`form-select ${formErrors.includes('id_type') ? 'error_field' : ''}`}
          inputStyles='!text-zinc-900'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-id_number'
          className={`w-1/3 flex form_label ${formErrors.includes('id_number') ? 'error_label' : ''}`}
        >
          {t('ID Number')}
          {requiredFields.includes('id_number') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='text'
          name='id_number'
          id='input-client-id_number'
          value={client?.id_number || ''}
          onChange={(e) => editField('id_number', e.target.value || '')}
          className={`fs-mask form-input w-2/3 ${formErrors.includes('id_number') ? 'error_field' : ''}`}
          autoComplete='false'
        />
      </div>

      {client.country && ['US'].includes(client.country) ? (
        <div className='w-full flex items-center'>
          <label
            htmlFor='input-client-id_state'
            className={`w-1/3 flex form_label ${formErrors.includes('id_state') ? 'error_label' : ''}`}
          >
            {t('ID State')}
            {requiredFields.includes('id_state') && [`Driver's License`].includes(client.id_type) ? (
              <span className='text-red-500'>*</span>
            ) : (
              ''
            )}
          </label>
          <MultiSelect
            selectId='select-client-id_state'
            inputId='input-client-id_state'
            options={statesList}
            onChange={(value) => editField('id_state', value)}
            value={defaultIdState}
            isClearable
            containerStyles='w-2/3'
            controlFocusedStyles='form-select form-select-focused'
            controlDefaultStyles={`form-select ${formErrors.includes('id_state') ? 'error_field' : ''}`}
            inputStyles='!text-zinc-900'
          />
        </div>
      ) : null}

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-birthday'
          className={`w-1/3 flex form_label ${formErrors.includes('birthday') ? 'error_label' : ''}`}
        >
          {t('Date of Birth')}
          {requiredFields.includes('birthday') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <input
          type='date'
          name='birthday'
          id='input-client-birthday'
          value={client?.birthday || ''}
          onChange={(e) => editBirthday(e.target.value || '')}
          className={`form-input w-2/3 ${formErrors.includes('birthday') ? 'error_field' : ''}`}
          autoComplete='false'
          max={maxDate}
          min={1900}
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-gender'
          className={`w-1/3 flex form_label ${formErrors.includes('gender') ? 'error_label' : ''}`}
        >
          {t('Gender')}
          {requiredFields.includes('gender') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <MultiSelect
          selectId='select-client-gender'
          inputId='input-client-gender'
          options={gendersList}
          onChange={(value) => editField('gender', value)}
          value={defaultGender}
          containerStyles='w-2/3'
          controlFocusedStyles='form-select form-select-focused'
          controlDefaultStyles={`form-select ${formErrors.includes('gender') ? 'error_field' : ''}`}
          inputStyles='!text-zinc-900'
        />
      </div>

      <div className='w-full flex items-center'>
        <label
          htmlFor='input-client-height'
          className={`w-1/3 flex form_label ${formErrors.includes('height_type') ? 'error_label' : ''}`}
        >
          {t('Height')}
          {requiredFields.includes('height_type') ? <span className='text-red-500'>*</span> : ''}
        </label>
        <div className='w-2/3 flex gap-1'>
          <MultiSelect
            selectId='select-client-height'
            inputId='input-client-height'
            options={heightList}
            onChange={(value) => editField('height_type', value)}
            value={defaultHeightType}
            containerStyles={client.height_type === 'Metric' ? 'w-[50%]' : 'w-[40%]'}
            controlFocusedStyles='form-select form-select-focused'
            controlDefaultStyles={`form-select ${formErrors.includes('height_type') ? 'error_field' : ''}`}
            dropdownIndicatorStyles='!px-[2px]'
            inputStyles='!text-zinc-900'
          />
          <div className={`flex items-center gap-1 ${client.height_type === 'Metric' ? 'w-[50%]' : 'w-[30%]'}`}>
            <input
              type='number'
              name='height_1'
              id='input-client-height_1'
              value={client?.height_1 || ''}
              onChange={(e) => editField('height_1', +e.target.value)}
              className='form-input w-full'
              autoComplete='false'
            />
            {client.height_type !== 'Metric' ? <span className='text-base text-zinc-900'>Ft</span> : null}
          </div>
          {client.height_type !== 'Metric' ? (
            <div className={`flex items-center gap-1 w-[30%]`}>
              <input
                type='number'
                name='height_2'
                id='input-client-height_2'
                value={client?.height_2 || ''}
                onChange={(e) => editField('height_2', e.target.value)}
                className='form-input w-full'
                autoComplete='false'
              />
              <span className='text-base text-zinc-900'>In</span>
            </div>
          ) : null}
        </div>
      </div>

      {appointmentInfo.appointment &&
      appointmentInfo.appointment.office.country &&
      appointmentInfo.appointment.office.country.toLowerCase() === 'es' ? (
        <>
          <div className='w-full flex items-center'>
            <label
              htmlFor='input-client-nationality'
              className={`w-1/3 flex form_label ${formErrors.includes('nationality') ? 'error_label' : ''}`}
            >
              {t('Nationality')}
              {requiredFields.includes('nationality') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='nationality'
              id='input-client-nationality'
              value={client?.nationality || ''}
              onChange={(e) => editField('nationality', e.target.value || '')}
              className={`form-input w-2/3 ${formErrors.includes('nationality') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>

          <div className='w-full flex items-center'>
            <label
              htmlFor='input-client-profession'
              className={`w-1/3 flex form_label ${formErrors.includes('profession') ? 'error_label' : ''}`}
            >
              {t('Profession')}
              {requiredFields.includes('profession') ? <span className='text-red-500'>*</span> : ''}
            </label>
            <input
              type='text'
              name='profession'
              id='input-client-profession'
              value={client?.profession || ''}
              onChange={(e) => editField('profession', e.target.value || '')}
              className={`form-input w-2/3 ${formErrors.includes('profession') ? 'error_field' : ''}`}
              autoComplete='false'
            />
          </div>
        </>
      ) : null}

      <div className='w-full flex items-center'>
        <div className={`w-1/3 flex form_label ${formErrors.includes('upload_id') ? 'error_label' : ''}`}>
          {t('Upload ID')}
        </div>

        {uploadId && uploadId[0] ? (
          <div className='w-2/3 flex items-center gap-2'>
            {uploadId[0].mimetype.includes('/pdf') ? (
              <>
                <a href={uploadId[0].url} target='_blank' rel='noreferrer' className='flex items-center gap-2'>
                  <IconPdf classname='h-[74px] w-[74px]' />
                  <div className='text-xs text-zinc-500'>{trimString(uploadId[0].name, 20)}</div>
                </a>
                <button type='button' onClick={() => setUploadId(null)}>
                  <IconClose />
                </button>
              </>
            ) : (
              <>
                <div className='h-[74px] w-[74px] border border-zinc-200 rounded flex justify-center items-center'>
                  <img src={resizeImage(uploadId[0].url)} alt='upload-id' className='max-w-full max-h-full' />
                </div>
                <div className='text-xs text-zinc-500'>{trimString(uploadId[0].name, 20)}</div>
                <button type='button' onClick={() => setUploadId(null)}>
                  <IconClose />
                </button>
              </>
            )}
          </div>
        ) : (
          <PhotoUploader
            blockStyle='w-2/3 flex flex-col gap-2'
            desktopButtonText='Choose file'
            desktopButtonStyle='identification-image-upload-button'
            showQRCodeButton
            qrcodeButtonText='From mobile'
            qrcodeButtonStyle='identification-image-upload-button'
            callback={(image) => setUploadId(image)}
          />
        )}
      </div>
    </div>
  )
}
