import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IImage, IPaymentInfo, IStore } from '../../../../types/storeTypes'
import { BankInfoBlock } from '../../../formBlocks/bankInfoBlock'
import { IdentificationBlock } from '../../../formBlocks/identificationBlock'
import { BuyerAndSourceBlock } from '../../../formBlocks/buyerAndSourceBlock'
import { getCurrency } from '../../../../utils/getCurrency'
import { PaymentInfoBlock } from '../../../formBlocks/paymentInfoBlock'
import { GiftCardInfoBlock } from '../../../formBlocks/giftCardInfoBlock'
import { DefaultButton } from '../../../defaultButton'
import { Loader } from '../../../loader'
import { useFormSession } from '../../../../hooks/useFormSession'
import { callApi } from '../../../../utils/callApi'
import { ApiRoutes } from '../../../../utils/apiRoutes'
import { showGlobalPopup } from '../../../../store/globalPopupSlice'
import { MessageModal } from '../../../modals/messageModal'
import { ClientShortInfoBlock } from '../../../formBlocks/clientShortInfoBlock'
import { updateClientInfo } from '../../../../store/clientInfoSlice'
import { setAppointmentBuyer } from '../../../../store/appointmentInfoSlice'
import { setGlobalPaymentData, setTurnoverId } from '../../../../store/paymentInfoSlice'
import { removePaymentInfoFromMobile } from '../../../../store/paymentInfoFromMobileSlice'
import { MultiSelect } from '../../../multiSelect'
import { SpainPaymentInfo } from '../../../formBlocks/spainPaymentInfo'
import { FORM_SUBMITTED } from '../../../../utils/consts'
import { useGetConsignedStepData } from '../../../../hooks/useGetConsignedStepData'
import { defaultClient } from '../../../../utils/defaultClient'
import moment from 'moment'

const defaultPaymentTypes = [
  {
    id: 'automatedACH',
    title: 'Automated ACH',
    value: 'Automate ACH',
  },
  {
    id: 'check',
    title: 'Check',
    value: 'Check',
  },
  {
    id: 'manualAch',
    title: 'Manual ACH/Wire',
    value: 'ACH Bank Transfer',
    value2: 'Wire Transfer',
  },
  {
    id: 'manualAch_es',
    title: 'Check/ACH/Wire',
    value: 'bank',
  },
]

const clientRequiredFields = [
  'first_name',
  'last_name',
  'email',
  'phone_no',
  'country',
  'province',
  'municipality',
  'address1',
  'postal_code',
  'email',
]

const restRequiredFields = [
  'buyer',
  'ns_lead_source',
  'birthday',
  'id_number',
  'id_type',
  'id_state',
  // 'gender',
  // 'turnover',
]

const allRequiredFields = [...clientRequiredFields, ...restRequiredFields]

export const PaymentStep = () => {
  const { t, i18n } = useTranslation()
  const [selectedPaymentType, setSelectedPaymentType] = useState({ id: '', title: '', value: '' })
  const client = useSelector((store: IStore) => store.client)
  const dicts = useSelector((store: IStore) => store.dicts)
  const currentStep = useSelector((store: IStore) => store.currentStep)
  const paymentInfo = useSelector((store: IStore) => store.paymentInfo)
  const appointmentInfo = useSelector((store: IStore) => store.appointmentInfo)
  const [clientInfo, setStateClientInfo] = useState<IStore['client']>(defaultClient)
  const [uploadId, setUploadId] = useState<IImage[] | null>(null)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [buyer, setBuyer] = useState<number>(0)
  const [turnover, setTurnover] = useState<number>(0)
  const [paymentData, setPaymentData] = useState<IPaymentInfo['payment_data'] | null>(null)
  const { stepsLoading, changeCurrentStep, getSixthStepData } = useGetConsignedStepData()
  const { stopCheckSession, saveConsignmentConversionSession } = useFormSession()
  const [saveLoading, setSaveLoading] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const dispatch = useDispatch()

  const isSpain = useMemo(() => {
    if (
      appointmentInfo.intermediate_save &&
      appointmentInfo.intermediate_save.office &&
      appointmentInfo.intermediate_save.office.country
    ) {
      return appointmentInfo.intermediate_save.office.country.toLowerCase() === 'es' ? true : false
    }
    if (appointmentInfo.appointment && appointmentInfo.appointment.office.country) {
      return appointmentInfo.appointment.office.country.toLowerCase() === 'es' ? true : false
    }
    return false
  }, [appointmentInfo.intermediate_save, appointmentInfo.appointment])

  const isHongkong = useMemo(() => {
    if (
      appointmentInfo.intermediate_save &&
      appointmentInfo.intermediate_save.office &&
      appointmentInfo.intermediate_save.office.country
    ) {
      return appointmentInfo.intermediate_save.office.country.toLowerCase() === 'hk' ? true : false
    }
    if (appointmentInfo.appointment && appointmentInfo.appointment.office.country) {
      return appointmentInfo.appointment.office.country.toLowerCase() === 'hk' ? true : false
    }
    return false
  }, [appointmentInfo.intermediate_save, appointmentInfo.appointment])

  useEffect(() => {
    const newClient = { ...client }
    if (client.identity && client.identity.height) {
      newClient.height_type = client.identity.height.measure
      newClient.height_1 =
        client.identity.height.measure === 'Metric'
          ? client.identity.height.value.cm || 0
          : client.identity.height.value.ft || 0
      newClient.height_2 = client.identity.height.value.in || 0
    }
    if (client.photo_id_url) {
      setUploadId([
        {
          name: t('Upload ID Photo'),
          content: '',
          url: client.photo_id_url,
          mimetype: client.photo_id_mimetype || '',
        },
      ])
    }
    setStateClientInfo(newClient)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  useEffect(() => {
    if (appointmentInfo && appointmentInfo.buyer_id) {
      setBuyer(appointmentInfo.buyer_id)
    } else if (appointmentInfo.intermediate_save && appointmentInfo.intermediate_save.buyer_id) {
      setBuyer(appointmentInfo.intermediate_save.buyer_id)
    }
  }, [appointmentInfo, appointmentInfo.buyer_id])

  const paymentTypes = useMemo(() => {
    if (isSpain || isHongkong) {
      let newTypes = defaultPaymentTypes
      newTypes = newTypes.filter((type) => type.id === 'manualAch_es')

      return newTypes
    }
    if (dicts && dicts.payment_types) {
      let newTypes = defaultPaymentTypes
      if (!dicts.payment_types?.includes('Automate ACH')) {
        newTypes = newTypes.filter((type) => type.id !== 'automatedACH')
      }
      if (!dicts.payment_types.includes('Check')) {
        newTypes = newTypes.filter((type) => type.id !== 'check')
      }
      if (!dicts.payment_types?.includes('ACH Bank Transfer') && !dicts.payment_types?.includes('Wire Transfer')) {
        newTypes = newTypes.filter((type) => type.id !== 'manualAch')
      }
      if (!isSpain && !isHongkong) {
        newTypes = newTypes.filter((type) => type.id !== 'manualAch_es')
      }

      return newTypes
    }

    return []
  }, [dicts, isSpain, isHongkong])

  useEffect(() => {
    if (paymentInfo && paymentInfo.payment_data) {
      setPaymentData({
        ...paymentInfo.payment_data,
        ...paymentData,
        payee_name: paymentInfo.payment_data.payee_name ? paymentInfo.payment_data.payee_name : clientInfo.full_name,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo.payment_data, clientInfo.full_name])

  useEffect(() => {
    if (paymentInfo && paymentInfo.buyer_id) {
      setBuyer(paymentInfo.buyer_id)
    }
  }, [paymentInfo, paymentInfo.buyer_id])

  useEffect(() => {
    if (paymentInfo && paymentInfo.turnover_id) {
      setTurnover(paymentInfo.turnover_id)
    }
  }, [paymentInfo, paymentInfo.turnover_id])

  const taxesList = useMemo(() => {
    if (!dicts || !dicts.taxes) return []
    return Object.keys(dicts.taxes).map((key) => {
      return { label: t(key), id: key, value: key }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicts, i18n.language])

  const defaultTax = useMemo(() => {
    return paymentData && paymentData.tax ? taxesList.find((listItem) => listItem.value === paymentData.tax) : null
  }, [paymentData, taxesList])

  const taxPercent = useMemo(() => {
    if (!dicts || !dicts.taxes) return ''
    return paymentData && paymentData.tax && dicts.taxes[paymentData.tax] ? dicts.taxes[paymentData.tax].percents : ''
  }, [paymentData, dicts])

  const editPaymentType = (newType: { id: string; title: string; value: string }) => {
    if (newType) {
      const taxValue = isSpain ? taxesList[0].value : undefined
      if (newType.id === 'giftCard') {
        setPaymentData({
          gift_card: 'Circa',
          tax: isSpain ? taxValue : undefined,
        })
      } else if (newType.id === 'automatedACH') {
        setPaymentData({
          account_number: paymentInfo.payment_data?.account_number,
          routing_number: paymentInfo.payment_data?.routing_number,
          plaid_account_id: paymentInfo.payment_data?.plaid_account_id,
          plaid_public_token: paymentInfo.payment_data?.plaid_public_token,
          promo_amount: paymentInfo.payment_data?.promo_amount || 200,
          payee_name:
            paymentInfo.payment_data?.payees &&
            paymentInfo.payment_data?.payees[0] &&
            paymentInfo.payment_data?.payees[0].payee_name
              ? paymentInfo.payment_data?.payees[0].payee_name
              : clientInfo.full_name,
          show_promo_amount: !!paymentInfo.payment_data?.promo_amount,
          is_plaid: paymentInfo.payment_data?.is_plaid,
          gift_card: '',
          payees: [],
          tax: isSpain ? taxValue : undefined,
        })
      } else {
        setPaymentData({
          gift_card: '',
          payees: [],
          tax: isSpain ? taxValue : undefined,
        })
      }
      setSelectedPaymentType(newType)
    }
  }

  useEffect(() => {
    if (paymentInfo.paymentInfoUploaded) {
      if (paymentTypes.length) {
        editPaymentType(paymentTypes[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentInfo.intermediate_save, paymentInfo.paymentInfoUploaded, paymentTypes])

  const checkForErrors = (type: 'client' | 'rest' | 'all') => {
    let fields = allRequiredFields
    if (type === 'client') fields = clientRequiredFields
    if (type === 'rest') fields = restRequiredFields
    if (type === 'all') fields = allRequiredFields
    const errors = fields
      .map((field) => {
        if (field === 'buyer') return !buyer ? field : ''
        else if (field === 'email') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'phone_no') {
          if (!clientInfo.email && (!clientInfo.phone_no || clientInfo.phone_no.length < 5)) return field
        } else if (field === 'postal_code') {
          if (!clientInfo.postal_code && clientInfo.country !== 'HK') return field
        } else if (field === 'province') {
          if (!clientInfo.province && ['CA', 'US'].includes(clientInfo.country)) return field
        } else if (field === 'id_state') {
          if (
            !clientInfo.id_state &&
            ['US'].includes(clientInfo.country) &&
            [`Driver's License`].includes(clientInfo.id_type)
          ) {
            return field
          }
        } else if (field === 'birthday') {
          if (!clientInfo.birthday || moment(clientInfo.birthday).year() < 1900) return field
        } else if (!clientInfo[field as keyof IStore['client']]) return field

        return ''
      })
      .filter((error) => !!error)

    if (errors.length > 0) {
      setFormErrors(errors)
      return false
    }

    return true
  }

  useEffect(() => {
    if (clientInfo.id) checkForErrors('client')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo])

  const automatedACHBankAccount = useMemo(() => {
    if (dicts && dicts.bank_accounts) {
      const account = dicts.bank_accounts.find((acc) => acc.destination === 'automatedACH')
      if (account) {
        return account
      } else return null
    } else {
      return null
    }
  }, [dicts])

  const onBack = () => {
    const skippedSignatureStep = appointmentInfo.intermediate_save?.consignmentConversion?.skippedSignatureStep
    if (skippedSignatureStep) {
      changeCurrentStep(currentStep - 2);
    } else {
      changeCurrentStep(currentStep - 1);
    }
  }

  const onSave = async () => {
    setSaveLoading(true)
    if (!checkForErrors('all')) {
      setOpenErrorModal(true)
      setSaveLoading(false)
      return null
    }
    stopCheckSession()

    const paymentFromStorage = appointmentInfo.intermediate_save?.paymentData
    const nextStep = 5
    let paymentSuccess = true

    try {
      const data = {
        first_name: clientInfo.first_name,
        last_name: clientInfo.last_name,
        email: clientInfo.email ? clientInfo.email : undefined,
        phone: clientInfo.phone_no ? clientInfo.phone_no : undefined,
        country: clientInfo.country,
        state: clientInfo.province,
        city: clientInfo.municipality,
        address1: clientInfo.address1,
        address2: clientInfo.address2,
        postal_code: clientInfo.postal_code,
        id_type: clientInfo.id_type,
        id_number: clientInfo.id_number,
        id_state: clientInfo.id_state,
        birthday: clientInfo.birthday,
        gender: clientInfo.gender,
        height: clientInfo.height_1
          ? {
              measure: clientInfo.height_type,
              value:
                clientInfo.height_type === 'Metric'
                  ? { cm: clientInfo.height_1 }
                  : {
                      ft: clientInfo.height_1,
                      in: clientInfo.height_2,
                    },
            }
          : null,
        id_file:
          uploadId && uploadId[0] && uploadId[0].url !== client.photo_id_url
            ? {
                url: uploadId[0].url,
                name: uploadId[0].name,
              }
            : null,
        buyer_id: buyer,
        source_id: clientInfo.ns_lead_source,
        turnover_id: !!turnover ? turnover : null,
        nationality: clientInfo.nationality,
        profession: clientInfo.profession,
        payment_data: {
          type: selectedPaymentType.id !== 'giftCard' ? selectedPaymentType.value : null,
          routing_number: selectedPaymentType.id === 'automatedACH' ? paymentData?.routing_number : null,
          account_number: selectedPaymentType.id === 'automatedACH' ? paymentData?.account_number : null,
          gift_card: selectedPaymentType.id === 'giftCard' ? paymentData?.gift_card : null,
          plaid_account_id:
            selectedPaymentType.id === 'automatedACH' &&
            paymentData?.plaid_account_id !== paymentFromStorage?.plaid_account_id
              ? paymentData?.plaid_account_id
              : undefined,
          plaid_public_token:
            selectedPaymentType.id === 'automatedACH' &&
            paymentData?.plaid_public_token !== paymentFromStorage?.plaid_public_token
              ? paymentData?.plaid_public_token
              : undefined,
          promo_amount:
            selectedPaymentType.id === 'automatedACH' && paymentData?.show_promo_amount
              ? paymentData?.promo_amount
              : null,
          tax: isSpain ? paymentData?.tax : undefined,
          payees:
            ['manualAch', 'check', 'manualAch_es'].includes(selectedPaymentType.id) && paymentData?.payees
              ? paymentData?.payees.map((payee) => {
                  return {
                    check_number: payee.check_number,
                    amount: payee.amount,
                    account_id: payee.account_id,
                    payee_name: payee.payee_name,
                    payee_email: payee.payee_email,
                  }
                })
              : selectedPaymentType.id === 'automatedACH'
                ? [
                    {
                      payee_name: paymentData?.payee_name,
                      amount: paymentInfo.amounts?.purchase,
                      account_id: automatedACHBankAccount ? automatedACHBankAccount.id : null,
                    },
                  ]
                : null,
        },
      }
      await callApi({
        method: 'POST',
        url: `${ApiRoutes.fourthStep}`,
        isConsignedConvert: true,
        data,
      })
      const newPaymentData = {
        ...paymentInfo.payment_data,
        ...data.payment_data,
        routing_number: paymentData?.routing_number || paymentInfo.payment_data?.routing_number,
        account_number: paymentData?.account_number || paymentInfo.payment_data?.account_number,
        plaid_public_token: paymentData?.plaid_public_token || paymentInfo.payment_data?.plaid_public_token,
        plaid_account_id: paymentData?.plaid_account_id || paymentInfo.payment_data?.plaid_account_id,
        promo_amount: paymentData?.promo_amount || paymentInfo.payment_data?.promo_amount,
        show_promo_amount: !!paymentData?.show_promo_amount,
        is_plaid: paymentData?.is_plaid,
        payee_name:
          data.payment_data.payees && data.payment_data.payees[0] ? data.payment_data.payees[0].payee_name : '',
      }
      dispatch(
        updateClientInfo({
          ...clientInfo,
          photo_id_url: uploadId && uploadId[0] ? uploadId[0].url : null,
          photo_id_mimetype: uploadId && uploadId[0] ? uploadId[0].mimetype : null,
        })
      )
      dispatch(setAppointmentBuyer(buyer))
      dispatch(setTurnoverId(turnover))
      dispatch(removePaymentInfoFromMobile())
      dispatch(setGlobalPaymentData(newPaymentData))
      await saveConsignmentConversionSession({
        currentStep: nextStep,
        paymentData: newPaymentData,
        buyer_id: buyer,
        client_name: `${data.first_name} ${data.last_name}`,
      })
    } catch (error) {
      console.log(error, 'error data')
      paymentSuccess = false
      setSaveLoading(false)
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
    }

    if (paymentSuccess) {
      try {
        await callApi({
          method: 'POST',
          url: `${ApiRoutes.formFinish}`,
        })
        const notes = appointmentInfo.intermediate_save?.decisionNotes || ''
        await getSixthStepData(notes)
        sessionStorage.setItem(FORM_SUBMITTED, 'true')
        changeCurrentStep(nextStep)
      } catch (error) {
        console.log(error, 'error data')
        dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
      } finally {
        setSaveLoading(false)
      }
    }
  }

  const getPercentAmount = (amount: number, percent: number) => {
    return +((amount / 100) * percent).toFixed(2)
  }

  const purchaseSummary = useMemo(() => {
    if (paymentInfo.amounts?.purchase) {
      if (paymentData?.tax === 'VAT' && taxPercent) {
        return getPercentAmount(paymentInfo.amounts.purchase, taxPercent) + paymentInfo.amounts.purchase
      } else {
        return paymentInfo.amounts.purchase
      }
    }

    return 0
  }, [paymentInfo.amounts?.purchase, paymentData?.tax, taxPercent])

  const disableSave =
    !selectedPaymentType ||
    !selectedPaymentType.id ||
    (selectedPaymentType.id === 'automatedACH' &&
      (!paymentData?.payee_name || !paymentData?.account_number || !paymentData?.routing_number)) ||
    (selectedPaymentType.id === 'giftCard' && !paymentData?.gift_card) ||
    (['manualAch', 'check', 'manualAch_es'].includes(selectedPaymentType.id) &&
      (!paymentData?.payees?.length ||
        (paymentInfo.amounts &&
          paymentData.payees.reduce((a, b) => {
            return a + (b.amount ? +b.amount : 0)
          }, 0) !== purchaseSummary) ||
        paymentData?.payees.some(
          (payee) => (!payee.amount && payee.amount !== 0) || !payee.check_number || !payee.payee_name
        )))

  const summaryBlock = (
    <>
      {!!paymentInfo.amounts?.purchase && !!purchaseSummary && (
        <div className='form_block w-full h-fit !bg-zinc-200'>
          <div className='form_block_title !text-slate-800'>{t('Purchase summary')}</div>
          <div className='flex justify-between gap-4'>
            <div className='text-base text-zinc-700'>{t('Purchase Amount')}</div>
            <div className='text-[22px] leading-7 font-medium'>
              {getCurrency(purchaseSummary, paymentInfo.amounts.currency)}
            </div>
          </div>
          {isSpain && (
            <>
              <div className='w-full flex items-center'>
                <label htmlFor={`input-payment-tax`} className='w-1/3 flex form_label'>
                  {t('ITP/VAT')}
                </label>
                <MultiSelect
                  selectId={`select-payment-tax`}
                  inputId={`input-payment-tax`}
                  options={taxesList}
                  onChange={(value) => setPaymentData({ ...paymentData, tax: value })}
                  value={defaultTax}
                  containerStyles='w-2/3'
                  controlFocusedStyles='form-select form-select-focused'
                  controlDefaultStyles={`form-select`}
                  inputStyles='!text-zinc-900'
                />
              </div>
              <div className='flex flex-col gap-1 text-base text-zinc-700'>
                <div className='flex justify-end'>
                  {t('Tax Amount ({{taxPercent}}%)', { taxPercent: taxPercent })}
                  {': '}
                  {paymentInfo.amounts.currency}{' '}
                  {taxPercent ? getPercentAmount(paymentInfo.amounts.purchase, taxPercent) : ''}
                </div>
                <div className='flex justify-end'>
                  {t('Total Amount')}
                  {': '}
                  {paymentInfo.amounts.currency}{' '}
                  {taxPercent
                    ? getPercentAmount(paymentInfo.amounts.purchase, taxPercent) + paymentInfo.amounts.purchase
                    : ''}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )

  return stepsLoading.includes(4) || !paymentTypes.length ? (
    <div className='w-full h-[70vh] flex justify-center items-center'>
      <Loader className='h-[100px]' />
    </div>
  ) : (
    <>
      <div className='flex flex-col gap-6'>
        <div className='flex gap-4 w-full overflow-x-auto custom_scroll pb-3'>
          {paymentTypes.map((paymentType) => {
            return (
              <button
                type='button'
                key={paymentType.id}
                id={`paymentType-${paymentType.id}`}
                className={`h-10 w-[200px] min-w-max whitespace-nowrap cursor-pointer rounded-[44px] max-w-[25%] flex justify-start items-center gap-2 p-2 text-lg font-medium ${paymentType.id === selectedPaymentType.id ? 'bg-green-700 text-white' : 'bg-white text-zinc-600'}`}
                onClick={() => editPaymentType(paymentType)}
              >
                <div
                  className={`bg-white rounded-full app w-[24px] h-[24px] flex justify-center items-center ${paymentType.id !== selectedPaymentType?.id ? 'border-2 border-zinc-300' : ''}`}
                >
                  {paymentType.id === selectedPaymentType.id && (
                    <div className='flex rounded-full w-[12px] h-[12px] bg-green-700'></div>
                  )}
                </div>
                {t(paymentType.title)}
              </button>
            )
          })}
        </div>

        <div className=' grid grid-cols-1 tabletMd:grid-cols-2 desktop:grid-cols-3 gap-6'>
          <div className='flex flex-col gap-6'>
            <ClientShortInfoBlock
              client={clientInfo}
              setClientInfo={setStateClientInfo}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={clientRequiredFields}
            />

            {selectedPaymentType && selectedPaymentType.id === 'automatedACH' ? (
              <BankInfoBlock paymentData={paymentData} setPaymentData={setPaymentData} isConsignedConvert={true} />
            ) : selectedPaymentType && selectedPaymentType.id === 'giftCard' ? (
              <GiftCardInfoBlock paymentData={paymentData} client={clientInfo} setPaymentData={setPaymentData} />
            ) : selectedPaymentType && selectedPaymentType.id === 'manualAch_es' && (isSpain || isHongkong) ? (
              <SpainPaymentInfo
                paymentType={selectedPaymentType.id}
                paymentData={paymentData}
                client={clientInfo}
                setPaymentData={setPaymentData}
                isConsignedConvert
                purchaseSummary={purchaseSummary}
              />
            ) : (
              <PaymentInfoBlock
                paymentType={selectedPaymentType.id}
                paymentData={paymentData}
                client={clientInfo}
                setPaymentData={setPaymentData}
                isConsignedConvert
              />
            )}
          </div>

          <IdentificationBlock
            client={clientInfo}
            setClientInfo={setStateClientInfo}
            uploadId={uploadId}
            setUploadId={setUploadId}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            requiredFields={allRequiredFields}
          />

          <div className='flex w-full flex-row [&>div]:w-full desktop:flex-col gap-6'>
            <BuyerAndSourceBlock
              buyer={buyer}
              setBuyer={setBuyer}
              turnover={turnover}
              setTurnover={setTurnover}
              client={clientInfo}
              setClientInfo={setStateClientInfo}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={allRequiredFields}
            />

            <div className='hidden w-full flex-col gap-6 desktop:flex'>{summaryBlock}</div>
          </div>

          <div className='flex w-full flex-col gap-6 desktop:hidden'>{summaryBlock}</div>
        </div>

        <div className='flex justify-between mb-5 mt-5'>
          <DefaultButton
            title={`← ${t('Go Back')}`}
            onClick={onBack}
            classname='secondary_button min-w-[200px]'
            disabled={saveLoading}
          />

          <DefaultButton
            title={`${t('Complete Appointment')} →`}
            onClick={onSave}
            classname='main_button min-w-[200px]'
            loading={saveLoading}
            disabled={saveLoading || disableSave}
          />
        </div>
      </div>

      <MessageModal
        opened={openErrorModal}
        title='Error'
        text={t('Please fill in all required fields')}
        onClose={() => setOpenErrorModal(false)}
        type='error'
        errors={formErrors}
      />
    </>
  )
}
